import React from 'react'
import styled from "styled-components"
import media from "styled-media-query";
import {Section} from "../components/Theme";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import Header from "../components/Header";
import Footer from '../components/footer';
import Carousel from "nuka-carousel";

const SlideButton = styled.button`
  font-size: 24px;
  padding: 10px 10px;
`;

const PageHeader = styled.h1 `
     grid-column: 2/8;
    padding: 40px 0;
    font-weight: 100;
    font-size: 52px;
    line-height: 79px;
    ${media.lessThan("large")`
        padding-bottom: 20px;
        font-size: 36px;
        line-height: 48px;
    `}
`

const CarouselFrame = styled(Carousel)`
    width: 100%;
    li {
        cursor: default;

    }
    .slider-frame {
        width: 100%;
    }
`;



const FeaturedImage = styled.div `
    grid-column: 2/12;

`
const PageContent = styled.div `
    grid-column: 3/11;
    padding: 80px 0;
    ${media.lessThan("large")`
    padding-top: 20px;
    `}

`
const LinkGrid = styled.div`
     display: grid;
    max-width: 1640px;
        width: 100%;
    grid-row-gap: 0px;
    grid-row: 4/5;
    grid-column: 3/11;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 80px;
    width: 100%;
    grid-column-gap: 50px;
    margin-bottom: 80px;
    ${media.lessThan("large")`
    display: block !important;
    `}
`

const BookLink = styled.a`
    font-size: 18px;
    line-height: 22px;
    background: #000;
    color: #fff;
    display: block;
    text-align: center;
    padding: 20px 30px;
    text-decoration: none;
    margin-bottom: 10px;
`

const ChildrensBook = ({ data: { childrens } }) => (
    <Layout parent="kids-book-page">
        <Header content_color="black" />
        <Section>
            <PageHeader>{childrens.title}</PageHeader>
            <FeaturedImage>
                <CarouselFrame
                    width="100%"
                    transitionMode="fade"
                    autoplayInterval={3000}
                    wrapAround
                    slideWidth={1.0}
                    initialSlidewidth={1.0}
                    autoplay
                    
                    renderCenterLeftControls={({ previousSlide }) => (
                        <SlideButton onClick={previousSlide}>&larr;</SlideButton>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                        <SlideButton onClick={nextSlide}>&rarr;</SlideButton>
                    )}

                >
                    <Img fluid={childrens.featuredImage.fluid} alt={childrens.title} />
                    {childrens.kidsBookImages.map((img, index) => (
                        <Img fluid={img.fluid} alt="" key={`img-${index}`} />
                    ))}
                </CarouselFrame>
            </FeaturedImage>
          
            <PageContent>
                <div
                    className="body-copy"
                    dangerouslySetInnerHTML={{ __html: childrens.description }}
                ></div>
            </PageContent>
            <LinkGrid>
                {childrens.links.map(link => (
                    <div className={`book-${link.model.apiKey}`} key={link.id}>
                        {link.model.apiKey === "link" && (
                            <BookLink href={link.url} target="_blank">
                                {link.name}
                            </BookLink>
                        )}
                    </div>
                ))}
            </LinkGrid>
        </Section>
        <Footer />
    </Layout>
);

export default ChildrensBook

export const query = graphql`
           query ChildrensBookQuery {
               childrens: datoCmsChildrensBook {
                   links {
                       name
                       url
                       model {
                           apiKey
                       }
                   }
                   title
                   description
                   kidsBookImages {
                       fluid {
                           base64
                           aspectRatio
                           width
                           height
                           src
                           srcSet
                           sizes
                       }
                   }
                   featuredImage {
                       fluid(
                           maxWidth: 2000
                           imgixParams: { fm: "jpg", auto: "compress" }
                       ) {
                           ...GatsbyDatoCmsFluid
                       }
                   }
               }
           }
       `;
